<template>
  <Disclosure as="nav" v-slot="{ open }">
    <div
      class="max-w-full mx-auto lg:border-b-grey-2 lg:border-b relative z-50 h-[80px]"
      :class="menuClass"
    >
      <div
        class="h-full flex justify-between items-center font-semibold text-sm xl:text-base constrain"
        :class="textColorClass"
      >
        <!-- Logo -->
        <div class="flex items-center h-full">
          <NuxtLink to="/">
            <CommonSVGTemplate
              @click="closeMenu"
              :class="logoClass"
              width="185"
              height="44"
              :alt="
                menuOpen ? 'JUSTHIRE white logo' : 'JUSTHIRE logo'
              "
              :path="logoPath"
            />
          </NuxtLink>
        </div>

        <!-- Desktop Menu -->
        <div class="hidden lg:flex lg:ml-6 gap-6 xl:gap-12">
          <NuxtLink
            v-for="item in menuItems"
            :key="item.name"
            :to="item.link"
            class="inline-flex items-center border-b-2 border-b-transparent"
            :class="props.isTransparent ? 'lg:hover:text-white lg:hover:border-white hover:text-primary-pressed hover:border-primary-pressed' : 'hover:text-primary-pressed hover:border-primary-pressed'"
          >
            {{ item.name }}
          </NuxtLink>
        </div>

        <!-- Book a Skip Button -->
        <div class="hidden lg:flex items-center">
          <NuxtLink to="/booking/location">
            <Button 
              :intent="props.isTransparent ? 'white-outline' : 'primary-outline'"
            >
              Book a skip
            </Button>
          </NuxtLink>
        </div>

        <!-- Hamburger Menu -->
        <div class="flex items-center lg:hidden">
          <DisclosureButton
            class="inline-flex items-center justify-center py-2 rounded-md text-base-black focus:outline-none"
            aria-label="Toggle menu"
          >
            <span class="sr-only">Open main menu</span>
            <component
              :is="menuOpen ? XMarkIcon : Bars3Icon"
              @click="toggleMenu"
              class="block h-10 w-10"
              :class="menuOpen || (props.isTransparent && window?.innerWidth >= 1024) ? 'text-white' : ''"
              aria-hidden="true"
            />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <!-- Mobile Menu -->
    <DisclosurePanel
      v-show="menuOpen"
      static
      class="lg:hidden fixed inset-0 mt-[80px] z-50 bg-white w-full h-full"
    >
      <CommonSVGTemplate
        alt="Gradient"
        width="100%"
        height="100%"
        :path="blueCircle"
        class="w-full h-full fixed z-10"
        loading="lazy"
      />

      <!-- Scrollable Container -->
      <div
        class="absolute z-20 w-full px-4 pt-2 pb-40 space-y-1 overflow-y-auto h-[calc(100vh-80px)]"
      >
        <span
          v-for="item in menuItems"
          :key="item.name"
          @click="handleMenuItemClick(item.link)"
          class="block border-b border-blue-lightest pr-4 py-6 text-base font-medium text-neutrals-800 leading-28"
        >
          {{ item.name }}
        </span>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import {
  ProjectLogo,
  WhiteLogo,
  BlueCircle,
} from "~/constants/AssetPaths";
import { useEventListener } from "@vueuse/core";

// Refs and router
const router = useRouter();
const menuOpen = ref(false);
const projectLogo = ref(ProjectLogo);
const whiteLogo = ref(WhiteLogo);
const blueCircle = ref(BlueCircle);
const isDesktop = ref(false);
const isHydrated = ref(false)

// Menu items
const menuItems = ref([
  { name: "Booking", link: "/booking/location" },
  { name: "About us", link: "/about-us" },
  { name: "Skip Hire", link: "/c/waste/skip-hire" },
  { name: "Knowledge base", link: "/knowledge" },
  { name: "Locations", link: "/locations" },
  { name: "Contact", link: "/contact-us" },
]);

// Add props definition at the top of <script setup>
const props = defineProps({
  isTransparent: {
    type: Boolean,
    default: false
  }
});

// Computed properties
const logoPath = computed(() => {
  if (menuOpen.value || (props.isTransparent && isDesktop.value)) {
    return whiteLogo.value;
  }
  return projectLogo.value;
});
const menuClass = computed(() => {
  if (!isHydrated.value) return 'invisible'
  if (menuOpen.value) return 'bg-energy-green'
  if (props.isTransparent) return 'lg:bg-transparent lg:border-b-0 bg-white'
  return 'bg-white'
});
const logoClass = "lg:w-[185px] w-[129px] lg:h-[44px] h-[31px]";
const textColorClass = computed(() => {
  if (!isHydrated.value) return 'invisible'
  return props.isTransparent ? 'lg:text-white text-grey-5' : 'text-grey-5'
});

// Methods
function handleMenuItemClick(link) {
  closeMenu();
  if (link) {
    router.push(link);
  }
}

function toggleBodyScroll(isDisabled) {
  if (isDisabled) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
}

function toggleMenu() {
  menuOpen.value = !menuOpen.value;
  updateThemeColor(menuOpen.value);
  toggleBodyScroll(menuOpen.value);
}

function closeMenu() {
  menuOpen.value = false;
  updateThemeColor(false);
  toggleBodyScroll(false);
}

// Theme color update
const THEME_COLORS = {
  menuOpen: "#28C700",
  default: "#FFFFFF",
};

function updateThemeColor(isOpen) {
  useHead({
    meta: [
      {
        name: "theme-color",
        content: isOpen
          ? THEME_COLORS.menuOpen
          : THEME_COLORS.default,
      },
    ],
  });
}

// Handle resize event for larger screens
function handleResize() {
  isDesktop.value = window.innerWidth >= 1024;
  if (isDesktop.value && menuOpen.value) closeMenu();
}

// Setup event listeners
onMounted(() => {
  handleResize()
  useEventListener(window, "resize", handleResize)
  nextTick(() => {
    isHydrated.value = true
  })
})
</script>

<style scoped>
.invisible {
  opacity: 0;
}
</style>
